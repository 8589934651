import { SeoHeader } from '@components/Common';
import { CookiePolicy } from '@components/Layouts';
import { Footer, NavigationHeader } from '@components/Navigation';
import { ResourceСategoriesMenu } from '@components/Resources';
import { categoryNameToSlug, Resource, ResourceCategory } from '@core';
import { Routes } from '@navigation';
import { mc } from '@styles';
import { clearHtmlTagText } from '@utils';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import parseHtml from 'html-react-parser';
import React, { FC } from 'react';

import MegaCategorySubcategory from './components/Subcategory';

interface Props {
  data: { circleof: { resourceFeed: { data: Resource[] } } };
  pageContext: PageContext;
}

interface PageContext {
  id: number;
  category: ResourceCategory;
  subcategories: ResourceCategory[];
}

const filterResourcesWithSubcategory = (resources: Resource[], subcategory: ResourceCategory) =>
  resources.filter(itm => {
    const cat = itm.subCategories.find(itm => itm.id === subcategory.id);
    return !!cat;
  });

export const MegaCategoryPage: FC<Props> = ({ data, pageContext: { category, subcategories } }) => {
  const resources = data.circleof.resourceFeed.data;
  const slug = categoryNameToSlug(category.name);
  return (
    <>
      <NavigationHeader />
      <CookiePolicy />
      <>
        <section className={mc('relative', `flex flex-col lg:flex-row lg:justify-between lg:items-stretch`, 'bg-ice-blue')}>
          <div className="px-8 lg:px-16 lg:pr-0 pt-8 lg:pt-24 pb-2 lg:pb-24 lg:w-[50%]">
            <a
              className={mc('font-raleway leading-normal font-semibold text-sm', 'hover:underline')}
              target="_blank"
              href={Routes.affiliateDisclosure.url}
            >
              {`Affiliate Disclosure`}
            </a>
            <h1 className="mt-1 p-0 text-5xl font-literata leading-tight font-medium text-ocean-blue text-left">
              {category.name}
            </h1>
            <div className="mt-3 text-2xl font-raleway leading-normal font-medium text-ocean-blue truncate text-left">
              {'What you need to know...'}
            </div>
            {!!category.description && (
              <div className="mt-3 text-lg font-raleway leading-normal font-medium text-ocean-blue space-y-4">
                {parseHtml(category.description)}
              </div>
            )}
          </div>
          <div className="flex flex-row-reverse lg:w-[40%] lg:flex-col-reverse lg:justify-self-stretch">
            {slug === 'care-support' && <StaticImage src="./assets/careSupport.png" alt="Care Support" />}
            {slug === 'legal' && <StaticImage src="./assets/legal.png" alt="Legal" />}
            {slug === 'finance' && <StaticImage src="./assets/finance.png" alt="Finance" />}
            {slug === 'health' && <StaticImage src="./assets/health.png" alt="Health" />}
            {slug === 'housing' && <StaticImage src="./assets/housing.png" alt="Housing" />}
          </div>
        </section>
        {!!subcategories.length && (
          <div className={mc('relative px-8 mb-8 -mt-[75px]', 'flex flex-row justify-center')}>
            <ResourceСategoriesMenu color="white" rootCategory={category} items={subcategories} linkType="anchor" />
          </div>
        )}
        <section className="bg-white pb-8 flex flex-col items-center">
          {subcategories.map(itm => (
            <MegaCategorySubcategory
              key={itm.id}
              className="w-full px-4 py-8"
              style={{ maxWidth: 984 }}
              id={categoryNameToSlug(itm.name)}
              category={category}
              subcategory={itm}
              resources={filterResourcesWithSubcategory(resources, itm)}
            />
          ))}
        </section>
      </>
      <Footer />
    </>
  );
};

export const Head: FC<{ pageContext: PageContext }> = ({ pageContext }) => (
  <SeoHeader title={pageContext.category.name} description={clearHtmlTagText(pageContext.category.description || '')} />
);

export const query = graphql`
  query ($id: Int!) {
    circleof {
      resourceFeed(filter: { isWeb: true }, limit: 500, offset: 0, categoryIds: [$id]) {
        data {
          ...ResourceFragment
        }
      }
    }
  }
`;

export default MegaCategoryPage;
