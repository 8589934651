import { TextBtn } from '@components/Buttons';
import { AffiliateInfoBox } from '@components/Resources';
import { Resource, ResourceCategory, subcategoryToUri } from '@core';
import { mc, StyleProps } from '@styles';
import parseHtml from 'html-react-parser';
import { size } from 'lodash';
import React, { FC } from 'react';

interface Props extends StyleProps {
  id?: string;
  category: ResourceCategory;
  subcategory: ResourceCategory;
  resources: Resource[];
}

const itemsPerCategory = 3;

export const MegaCategorySubcategory: FC<Props> = ({ className, style, id, category, subcategory, resources }) => {
  const { name, description, featuredMerchantIds } = subcategory;
  const getItems = () => {
    if (!featuredMerchantIds) return resources;
    const items = resources.filter(itm => featuredMerchantIds.includes(itm.id));
    // Sorting according to the order of featuredMerchantIds
    return items.sort((a, b) => featuredMerchantIds.indexOf(a.id) - featuredMerchantIds.indexOf(b.id));
  };
  const items = getItems();
  const visibleItems = items.slice(0, itemsPerCategory);
  return (
    <div id={id} className={mc(className)} style={style}>
      <h3 className={mc('m-0', 'text-4xl font-literata leading-tight font-medium text-ocean-blue', 'text-left')}>{name}</h3>
      {!!description && (
        <div className="mt-4 text-lg font-raleway leading-normal font-medium text-ocean-blue space-y-4">
          {parseHtml(description)}
        </div>
      )}
      {visibleItems.map(itm => (
        <AffiliateInfoBox key={itm.id} className="py-6 sm:py-3 mt-8 border-b" data={itm} />
      ))}
      {size(resources) > itemsPerCategory && (
        <div className="pt-8 flex flex-row-reverse">
          <TextBtn to={subcategoryToUri(category, subcategory)} fontWeight="bold" fontSize="lg" rightIcon="chevronRight">
            {`See More`}
          </TextBtn>
        </div>
      )}
    </div>
  );
};

export type MegaCategorySubcategoryProps = Props;
export default MegaCategorySubcategory;
